import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer"
import { AppThunk } from "reducers/types"
import { CallState, CallType, TimestampAction, isActiveCall } from "utils/calls"
import { logger } from "utils/utils"
import { getActiveAccount } from "./accountThunk"
import { deleteCall, removeCall, updateCall } from "reducers/slices/callReducer"
import { addNewCallHistoryCount } from "reducers/slices/callHistoryReducer"
import { clearSlotsCooldown, selectSlot, setActiveCall, setSelectedCall, unclaimCallSlot } from "reducers/slices/callSlotReducer"
import { removeCache } from "reducers/slices/cacheReducer"

export const hasProcessingCall = (): AppThunk<boolean> => {
  return (dispatch, getState) => {
    const calls = getState().call
    const isAnyCallProcessing = Object.values(calls)
      .filter(e => !e?.isRemoved)
      .some(e => e?.isProcessing)

    if (isAnyCallProcessing) {
      dispatch(setSnackbar({
        message: 'Another call is still processing, please wait for a moment.',
        severity: SnackbarSeverity.WARNING
      }))
      logger('Another call is still processing, canceling action.')
      return true
    }

    return false
  }
}

export const getCallByConferenceName = (conferenceName: string, skipRemoved = true): AppThunk<CallState | undefined> => {
  return (dispatch, getState) => {
    const calls = getState().call
    if (calls[conferenceName]?.isRemoved && skipRemoved) return undefined

    return calls[conferenceName]
  }
}

// Not in warm transfer or waiting for BE
export const canOutboundCall = (): AppThunk<boolean> => {
  return (dispatch, getState) => {
    const account = dispatch(getActiveAccount())
    if (!account?.id) {
      dispatch(setSnackbar({
        message: 'Please select an organization before you can create an outgoing call.',
        severity: SnackbarSeverity.ERROR
      }))
      return false
    }

    const calls = getState().call
    const user = getState().user.user
    const existingOutboundCall = Object.values(calls)
      .filter(call => !call?.isRemoved)
      .find(call => call && call.type === CallType.OUTBOUND && call.conferenceJoinedBy === user?.userId)
    if (existingOutboundCall) {
      dispatch(setSnackbar({
        message: 'Only one outgoing call can be performed at a time.',
        severity: SnackbarSeverity.ERROR
      }))
      return false
    }

    return true
  }
}

export const getActiveCall = (): AppThunk<CallState | undefined> => {
  return (dispatch, getState) => {
    return getState().callSlot.activeCall
  }
}

export const getSelectedCall = (): AppThunk<CallState | undefined> => {
  return (dispatch, getState) => {
    return getState().callSlot.selectedCall
  }
}

export const removeCallThunk = (conferenceName: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const call = state.call[conferenceName]
    if (call) {
      dispatch(removeCall(conferenceName))

      const selectedCall = state.callSlot.selectedCall
      if (selectedCall?.conferenceName === conferenceName) {
        dispatch(setSelectedCall(undefined))
      }
      const activeCall = state.callSlot.activeCall
      if (activeCall?.conferenceName === conferenceName) {
        dispatch(setActiveCall(undefined))
      }

      if (call.accountId) {
        dispatch(removeCache(call.accountId))
      }

      // A call has been removed (completed), it's considered as new history that has not been fetched.
      dispatch(addNewCallHistoryCount())
    }
  }
}

export const isSocketOffline = (): AppThunk<boolean> => {
  return (dispatch, getState) => {
    const isOffline = getState().ui.noConnection

    if (isOffline) {
      dispatch(setSnackbar({
        message: 'Failed to establish connection with websocket.',
        severity: SnackbarSeverity.ERROR
      }))
      logger('Disconnected from websocket, action declined.')
      return true
    }

    return false
  }
}

export const unclaimCallSlotThunk = (conferenceName: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(unclaimCallSlot(conferenceName))
    setTimeout(() => {
      dispatch(clearSlotsCooldown())
    }, 550)
  }
}

export const selectSlotThunk = (slotName: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(selectSlot(slotName))

    const calls = state.call
    const assignedCalls = state.callSlot.assignedCalls
  
    const selectedConfName = assignedCalls[slotName || '']
    const selectedCall = calls[selectedConfName || '']

    dispatch(setSelectedCall(selectedCall))
  }
}

interface UpdateCallPayload {
  conferenceName: string,
  update: Partial<CallState>,
  addIfMissing?: boolean, // For requeue case, update if exists, add if not.
}

export const updateCallThunk = (payload: UpdateCallPayload): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()

    let callState: CallState | undefined = undefined

    const { conferenceName, update, addIfMissing } = payload
    // @ts-ignore call is not undefined
    if (state.call[conferenceName] && !state.call[conferenceName].isRemoved) {
      const prevState = state.call[conferenceName]
      /**
       * There are 2 sources of timestamps update
       * Optimistic update -> update timestamps from user actions, without waiting for server events
       * Realistic update -> update timestamps from server events
       * 
       * If we already did optimistic, then disregard the realistic.
       */

      const updateTimestamps = (update?.timestamps || [])
      const prevTimestamps = (prevState?.timestamps || [])

      const timestampUpdate = updateTimestamps.slice().shift()
      const latestTimestamp = prevTimestamps.slice().shift()

      let updatedTimestamps = [
        ...updateTimestamps, // Latest at top
        ...prevTimestamps,
      ]
      if (latestTimestamp && timestampUpdate) {
        if (latestTimestamp.action === timestampUpdate.action && timestampUpdate.action !== TimestampAction.REQUEUE) {
          updatedTimestamps = prevTimestamps
        }
      }

      let transfersDialed = prevState?.transfersDialed || []
      if (update.transfersDialed) {
        transfersDialed = transfersDialed.concat(update.transfersDialed)
      }

      callState = {
        ...prevState,
        isProcessing: update.state !== undefined ? false : prevState?.isProcessing, // set to false if update includes state changes
        ...update,
        timestamps: updatedTimestamps,
        transfersDialed,
        updatedAt: Date.now(),
      } as CallState
    } else if (addIfMissing) {
      callState = {
        conferenceName,
        ...update,
        createdAt: Date.now(),
        isRemoved: false,
        removedAt: undefined,
      } as CallState
    }

    if (callState) {
      dispatch(updateCall(callState))
      const selectedCall = state.callSlot.selectedCall
      if (selectedCall?.conferenceName === conferenceName) {
        dispatch(setSelectedCall(callState))
      }
      const activeCall = state.callSlot.activeCall
      if (activeCall?.conferenceName === conferenceName) {
        if (isActiveCall(callState, state.user.user?.userId)) {
          dispatch(setActiveCall(callState))
        } else {
          dispatch(setActiveCall(undefined))
        }
      }
    }
  }
}

export const stopAllProcessing = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const calls = state.call

    Object.values(calls).forEach(call => {
      if (call && !call.isRemoved) {
        const updatedCall = { ...call, isProcessing: false }
        dispatch(updateCall(updatedCall))

        const selectedCall = state.callSlot.selectedCall
        if (selectedCall?.conferenceName === call.conferenceName) {
          dispatch(setSelectedCall(updatedCall))
        }

        const activeCall = state.callSlot.activeCall
        if (activeCall?.conferenceName === call.conferenceName) {
          if (isActiveCall(updatedCall, state.user.user?.userId)) {
            dispatch(setActiveCall(updatedCall))
          } else {
            dispatch(setActiveCall(undefined))
          }
        }
      }
    })
  }
}

export const getCallerDelay = (): AppThunk<string> => {
  return (dispatch, getState) => {
    return getState().ui.callerDelay
  }
}

export const clearRemovedCalls = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const calls = state.call
    const now = Date.now()

    Object.values(calls).forEach(call => {
      if (call && call.isRemoved) {
        if (call.removedAt && now - call.removedAt >= 60 * 1000) {
          dispatch(deleteCall(call.conferenceName))
        }
      }
    })
  }
}