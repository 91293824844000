import { FC, useMemo } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Phone, PriorityHigh, WarningRounded } from "@mui/icons-material";
import { Contact, getContactName, getContactUnavailability, getTypeOfCalls } from "utils/accounts";
import Highlighter from 'react-highlight-words';
import { formatDate } from "utils/utils";
import { useDispatch } from "react-redux";
import { setContactForNewStatus } from "reducers/slices/UIReducer";

interface IProps {
  search: string,
  contact: Contact,
}

const TAKES_CALLS_COLORS: Record<string, string> = {
  Yes: '#448C3D',
  No: '#E80B0B',
  'Emergency Calls Only': '#FFB200',
  Default: '#E80B0B',
}

const ContactStatus: FC<IProps> = ({ contact, search }) => {
  const dispatch = useDispatch()
  const typesOfCalls: string = useMemo(() => {
    return getTypeOfCalls(contact)
  }, [contact])

  const currentUnavailability = getContactUnavailability(contact, true)
  const unavailability = getContactUnavailability(contact, false)
  return (
    <Box width="100%">
      <Stack direction="row" alignItems="center">
        <Phone
          sx={{
            color: TAKES_CALLS_COLORS[contact.doYouTakeCalls || 'Default'],
            fontSize: '18px',
          }}
        />
        {contact.doYouTakeCalls === 'Emergency Calls Only' &&
          <PriorityHigh sx={{ mx: '-6px', color: TAKES_CALLS_COLORS['Emergency Calls Only'], fontSize: '18px' }} />
        }
        <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#1033A5', mx: 1 }}>
          <Highlighter
            searchWords={[search.toLowerCase()]}
            autoEscape={true}
            textToHighlight={getContactName(contact)}
          />
        </Typography>
        {contact.title &&
          <Typography style={{ fontWeight: 600, fontSize: '13px' }}>
            <Highlighter
              searchWords={[search.toLowerCase()]}
              autoEscape={true}
              textToHighlight={contact.title}
            />
          </Typography>
        }
      </Stack>

      {contact.displayAsAlert &&
        <>
          {(contact.doYouTakeCalls === 'Yes' &&
            (contact.currentStatusNote || contact.doNotConnect)
          ) &&
            <Box
              sx={{
                my: '4px', p: '12px',
                width: 'calc(100% - 24px - 2px)',
                border: '1px solid rgba(68, 140, 61, 0.6)',
                borderRadius: '5px',
                backgroundColor: 'rgba(68, 140, 61, 0.2)'
              }}
            >
              <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
                {formatDate(contact.currentStatusAppliedDate, 'MM/DD/YYYY h:mm A')}
                {' - '}
                {contact.currentStatusExpirationDate ? formatDate(contact.currentStatusExpirationDate, 'MM/DD/YYYY h:mm A') : 'UFN'}
              </Typography>
              <Typography style={{ fontSize: '12px' }}>Taking Calls</Typography>
              {contact.doNotConnect &&
                <Typography style={{ fontSize: '12px' }}>
                  <Highlighter
                    searchWords={[search.toLowerCase()]}
                    autoEscape={true}
                    textToHighlight={`Do not connect: ${contact.doNotConnect.split(';').join(',')}`}
                  />
                </Typography>
              }
              {contact.currentStatusNote &&
                <Typography style={{ fontSize: '12px' }}>
                  <Highlighter
                    searchWords={[search.toLowerCase()]}
                    autoEscape={true}
                    textToHighlight={`Note: ${contact.currentStatusNote}`}
                  />

                </Typography>
              }
            </Box>
          }

          {(contact.doYouTakeCalls !== 'Yes' &&
            (currentUnavailability || contact.connectOnly || contact.currentStatusNote)
          ) &&
            <Box
              sx={{
                my: '4px', p: '12px',
                width: 'calc(100% - 24px - 2px)',
                border: '1px solid rgba(232, 11, 11, 0.6)',
                borderRadius: '5px',
                backgroundColor: 'rgba(232, 11, 11, 0.2)'
              }}
            >
              {contact.currentStatusAppliedDate &&
                <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
                  {formatDate(contact.currentStatusAppliedDate, 'MM/DD/YYYY h:mm A')}
                  {' - '}
                  {contact.currentStatusExpirationDate ? formatDate(contact.currentStatusExpirationDate, 'MM/DD/YYYY h:mm A') : 'UFN'}
                </Typography>
              }
              <Typography style={{ fontSize: '12px' }}>
                <Highlighter
                  searchWords={[search.toLowerCase()]}
                  autoEscape={true}
                  textToHighlight={currentUnavailability}
                />
              </Typography>
              {contact.connectOnly &&
                <Typography style={{ fontSize: '12px' }}>
                  <Highlighter
                    searchWords={[search.toLowerCase()]}
                    autoEscape={true}
                    textToHighlight={`Connect only: ${contact.connectOnly.split(';').join(',')}`}
                  />

                </Typography>
              }
              {contact.currentStatusNote &&
                <Typography style={{ fontSize: '12px' }}>
                  <Highlighter
                    searchWords={[search.toLowerCase()]}
                    autoEscape={true}
                    textToHighlight={`Note: ${contact.currentStatusNote}`}
                  />
                </Typography>
              }
            </Box>
          }
        </>
      }

      {!contact.displayAsAlert &&
        <Button
          onClick={() => dispatch(setContactForNewStatus(contact))}
          sx={{
            my: '4px', p: '12px',
            height: 45,
            border: '1px solid #1033A5',
            borderRadius: '5px',
            backgroundColor: '#FFF',
            justifyContent: 'flex-start',
          }}
          fullWidth
          disableElevation
        >
          <Stack direction="row" alignItems="center">
            <WarningRounded sx={{ color: '#1033A5', fontSize: '20px', mr: '4px' }} />
            <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#1033A5' }}>
              Create Alert
            </Typography>
          </Stack>
        </Button>
      }

      {unavailability &&
        <Box
          sx={{
            my: '4px', p: '12px',
            width: 'calc(100% - 24px - 2px)',
            border: '1px solid rgba(143, 96, 14, 0.8)',
            borderRadius: '5px',
            backgroundColor: 'rgba(143, 96, 14, 0.5)'
          }}
        >
          <Typography sx={{ fontSize: '13px' }}>
            <Highlighter
              searchWords={[search.toLowerCase()]}
              autoEscape={true}
              textToHighlight={unavailability}
            />
          </Typography>
        </Box>
      }


      <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
        Takes Calls:&nbsp;
        <span style={{ fontWeight: 'normal' }}>
          <Highlighter
            searchWords={[search.toLowerCase()]}
            autoEscape={true}
            textToHighlight={contact.doYouTakeCalls || 'No'}
          />
        </span>
      </Typography>
      <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
        Type:&nbsp;
        <span style={{ fontWeight: 'normal' }}>
          <Highlighter
            searchWords={[search.toLowerCase()]}
            autoEscape={true}
            textToHighlight={typesOfCalls}
          />
        </span>
      </Typography>
      {contact.daysAndTimesYouTakeLiveCalls &&
        <Typography sx={{ fontSize: '13px' }}>
          <Highlighter
            searchWords={[search.toLowerCase()]}
            autoEscape={true}
            textToHighlight={contact.daysAndTimesYouTakeLiveCalls}
          />
        </Typography>
      }
    </Box>
  )
}

export default ContactStatus;
