import * as yup from 'yup';
import { AddCircle, Check, Circle, Close, KeyboardArrowDown } from "@mui/icons-material";
import {
  Badge,
  Box, Button, Checkbox, Chip, Collapse, Dialog, FormControlLabel, FormHelperText, IconButton,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setContactForNewStatus, setSnackbar, SnackbarSeverity } from "reducers/slices/UIReducer";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { DateTimePicker } from "@mui/x-date-pickers";
import AnimatedButton from "components/buttons/AnimatedButton";
import { createAlert } from 'api/contacts';
import parseYupErrors from 'utils/parseYupErrors';
import { useAppSelector } from 'reducers/hooks';
import { updateContact } from 'reducers/slices/accountPanelReducer';

const OPTIONS = [
  { value: 'available', label: 'Available', color: '#448C3D' },
  { value: 'unavailable', label: 'Unavailable', color: '#E80B0B' },
]

const UNAVAILABILITY_OPTIONS = [
  'Unavailable',
  'In a meeting',
  'With a client',
  'On the other line',
  'Out of the office',
  'Other',
]

const formSchema = yup.object().shape({
  availability: yup.string().required(),
  endTime: yup.mixed().label('End Time')
    .test('end-time-required', 'End Time is required.',
      (value, testContext) => !!value || testContext.parent.untilFurtherNotice
    ),
  untilFurtherNotice: yup.boolean(),

  dontConnectCallers: yup.array().of(yup.string()),
  connectCallers: yup.array().of(yup.string()),
  unavailabilityExplanations: yup.string(),
  unavailabilityExplanationsOther: yup.string(),

  additionalNote: yup.string(),
})

type FormState = {
  availability: string,
  endTime: Date | null,
  untilFurtherNotice: boolean,

  dontConnectCallers: string[],
  connectCallers: string[],
  unavailabilityExplanations: string,
  unavailabilityExplanationsOther: string,

  additionalNote: string,
}

const DEFAULT_STATE: FormState = {
  availability: '',
  endTime: null,
  untilFurtherNotice: false,

  dontConnectCallers: [],
  connectCallers: [],
  unavailabilityExplanations: '',
  unavailabilityExplanationsOther: '',

  additionalNote: '',
}

const ContactStatusDialog = () => {
  const [formState, setFormState] = useState<FormState>(cloneDeep(DEFAULT_STATE))
  const [errors, setErrors] = useState<Partial<Record<keyof FormState, string>>>({})
  const [dontConnectCaller, setDontConnectCaller] = useState<string>('')
  const [connectCaller, setConnectCaller] = useState<string>('')
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const contactForNewStatus = useAppSelector(state => state.ui.contactForNewStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!contactForNewStatus) {
      setFormState(cloneDeep(DEFAULT_STATE))
      setDontConnectCaller('')
      setConnectCaller('')
      setShowAdvanced(false)
    }
  }, [contactForNewStatus])

  const onChange = (prop: keyof FormState) => (value: any) => {
    setFormState((oldState) => ({
      ...oldState,
      [prop]: value,
    }))
  }

  const onCallerKeyDown = (type: 'dontConnectCallers' | 'connectCallers') => (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (type === 'dontConnectCallers') {
        addDontConnectCaller()
      } else {
        addConnectCaller()
      }
    }
  }

  const addDontConnectCaller = () => {
    const newValue = dontConnectCaller.trim()
    if (newValue) {
      if (!formState.dontConnectCallers.includes(newValue)) {
        setFormState((oldState) => ({
          ...oldState,
          dontConnectCallers: oldState.dontConnectCallers.concat([newValue]),
        }))
      }
    }

    setDontConnectCaller('')
  }

  const addConnectCaller = () => {
    const newValue = connectCaller.trim()
    if (newValue) {
      if (!formState.connectCallers.includes(newValue)) {
        setFormState((oldState) => ({
          ...oldState,
          connectCallers: oldState.connectCallers.concat([newValue]),
        }))
      }
    }

    setConnectCaller('')
  }

  const onChipDelete = (prop: 'dontConnectCallers' | 'connectCallers', value: string) => {
    setFormState((oldState) => ({
      ...oldState,
      [prop]: oldState[prop].filter(e => e !== value),
    }))
  }

  const handleClose = () => {
    dispatch(setContactForNewStatus(null))
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    setErrors({})

    formSchema
      .validate(formState, { abortEarly: false })
      .then(() => {
        const body = {
          takeCalls: formState.availability === 'available' ? 'Yes' : 'No',
          applyStatusOn: null,
          revertStatusOn: formState.untilFurtherNotice ? null : formState.endTime,

          doNotConnect: formState.dontConnectCallers.join(';'),
          connectOnly: formState.connectCallers.join(';'),
          unavailabilityExplanation: formState.unavailabilityExplanations,
          unavailabilityExplanationOther: formState.unavailabilityExplanationsOther,
          statusNote: formState.additionalNote,
        }
        createAlert(contactForNewStatus?.accountId || '', contactForNewStatus?.id || '', body)
          .then((res) => {
            dispatch(setSnackbar({ message: 'Contact Call Status created!', severity: SnackbarSeverity.SUCCESS }))
            dispatch(setContactForNewStatus(null))
            if (contactForNewStatus) {
              dispatch(updateContact({
                id: contactForNewStatus.id,
                update: {
                  currentStatusAppliedDate: (new Date()).toISOString(),
                  currentStatusExpirationDate: body.revertStatusOn ? body.revertStatusOn.toISOString() : null,

                  displayAsAlert: true,
                  doYouTakeCalls: body.takeCalls,
                  doNotConnect: body.doNotConnect,
                  connectOnly: body.connectOnly,
                  unavailabilityExplanation: body.unavailabilityExplanation,
                  unavailabilityExplanationOther: body.unavailabilityExplanationOther,
                  additionalStatusNote: body.statusNote,
                }
              }))
            }
          })
          .catch((err) => {
            dispatch(setSnackbar({ message: err.response.data, severity: SnackbarSeverity.ERROR }))
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      })
      .catch((err: yup.ValidationError) => {
        setErrors(parseYupErrors(err))
        setIsSubmitting(false)
      })
  }

  const showBadge = !showAdvanced && (
    (formState.availability === 'available' && !!formState.dontConnectCallers.length) ||
    (formState.availability === 'unavailable' && (!!formState.connectCallers.length || !!formState.unavailabilityExplanations)) ||
    !!formState.additionalNote
  )

  return (
    <Dialog open={!!contactForNewStatus} onClose={handleClose}>
      <Box width={450} px={4} py={3}>
        <Box display="flex" alignItems="center">
          <Typography style={{ fontWeight: 600, fontSize: 22 }}>New Scheduled Call Status</Typography>
          <IconButton sx={{ marginLeft: 'auto' }} onClick={handleClose}>
            <Close sx={{ color: 'black' }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 2, p: 1,
            backgroundColor: 'rgb(242, 242, 242)',
            borderRadius: '5px',
          }}
        >
          {OPTIONS.map(option => (
            <ListItemButton
              key={option.value}
              sx={{ px: 1 }}
              onClick={() => onChange('availability')(option.value)}
            >
              <Circle sx={{ color: option.color, mr: 2, fontSize: '16px' }} />
              <Typography style={{ fontWeight: 600 }}>{option.label}</Typography>
              {option.value === formState.availability && <Check sx={{ marginLeft: 'auto' }} />}
            </ListItemButton>
          ))}
          {errors.availability && <FormHelperText error>Please choose an availability.</FormHelperText>}
        </Box>

        <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>End Time</Typography>
        <DateTimePicker
          sx={{ mt: 1, width: '100%' }}
          onChange={onChange('endTime')}
          value={formState.endTime}
          slotProps={{
            textField: {
              size: 'small',
              InputProps: {
                sx: { bgcolor: '#E4ECFC' },
              }
            },
            openPickerIcon: {
              sx: { color: '#A5B5E3' }
            },
          }}
          disablePast
          disabled={formState.untilFurtherNotice}
        />
        {errors.endTime && <FormHelperText error>End Time is a required field.</FormHelperText>}

        <FormControlLabel
          sx={{ mt: 1 }}
          label="Until further notice"
          control={
            <Checkbox
              sx={{ mr: '4px' }}
              checked={formState.untilFurtherNotice}
              onChange={(e) => onChange('untilFurtherNotice')(e.target.checked)}
            />
          }
        />

        <Stack alignItems="center" sx={{ mt: 2 }}>
          <Button
            endIcon={
              <Badge
                variant="dot"
                color="primary"
                invisible={!showBadge}
              >
                <KeyboardArrowDown
                  sx={{
                    color: 'rgb(95, 39, 203)',
                    rotate: showAdvanced ? '-180deg' : '0deg',
                    transition: 'rotate 0.3s',
                  }}
                />
              </Badge>
            }
            onClick={() => setShowAdvanced(!showAdvanced)}
            disableElevation
            size="small"
            sx={{
              width: 200,
              color: 'rgb(95, 39, 203)',
            }}
          >
            Advanced Settings
          </Button>
        </Stack>

        <Collapse in={showAdvanced && formState.availability === 'available'}>
          <Box>
            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Don't connect the following callers</Typography>
            <FormHelperText
              error={formState.dontConnectCallers.length >= 10}
              sx={{ mt: '-4px' }}
            >
              You can add up to 10 callers
            </FormHelperText>

            <TextField
              fullWidth
              size="small"
              value={dontConnectCaller}
              onChange={(e) => setDontConnectCaller(e.target.value)}
              onKeyDown={onCallerKeyDown('dontConnectCallers')}
              disabled={formState.dontConnectCallers.length >= 10}
              sx={{ '& .MuiOutlinedInput-root': { paddingRight: 0 } }}
              inputProps={{ maxLength: 25 }}
              InputProps={{
                sx: { bgcolor: '#E4ECFC' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: '#A5B5E3' }}
                      disabled={!dontConnectCaller || formState.dontConnectCallers.length >= 10}
                      onClick={addDontConnectCaller}
                    >
                      <AddCircle />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
              {formState.dontConnectCallers.map(caller => (
                <Chip
                  sx={{ mt: '4px', mr: '4px' }}
                  key={caller}
                  label={caller}
                  onDelete={() => onChipDelete('dontConnectCallers', caller)}
                />
              ))}
            </Stack>

            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Additional Note</Typography>
            <TextField
              sx={{
                bgcolor: '#E4ECFC',
                borderRadius: '5px',
                mt: 1,
              }}
              InputProps={{ sx: { p: 1 } }}
              inputProps={{ style: { fontSize: '14px' }, maxLength: 250 }}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={formState.additionalNote}
              onChange={e => onChange('additionalNote')(e.target.value)}
            />
            <FormHelperText sx={{ textAlign: 'right', pr: '14px' }}>
              {formState.additionalNote.length + '/250'}
            </FormHelperText>
          </Box>
        </Collapse>


        <Collapse in={showAdvanced && formState.availability === 'unavailable'}>
          <Box>
            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Connect only the following callers</Typography>
            <FormHelperText
              error={formState.connectCallers.length >= 10}
              sx={{ mt: '-4px' }}
            >
              You can add up to 10 callers
            </FormHelperText>

            <TextField
              fullWidth
              size="small"
              value={connectCaller}
              onChange={(e) => setConnectCaller(e.target.value)}
              onKeyDown={onCallerKeyDown('connectCallers')}
              disabled={formState.connectCallers.length >= 10}
              sx={{ '& .MuiOutlinedInput-root': { paddingRight: 0 } }}
              inputProps={{ maxLength: 25 }}
              InputProps={{
                sx: { bgcolor: '#E4ECFC' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: '#A5B5E3' }}
                      disabled={!connectCaller || formState.connectCallers.length >= 10}
                      onClick={addConnectCaller}
                    >
                      <AddCircle />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
              {formState.connectCallers.map(caller => (
                <Chip
                  sx={{ mt: '4px', mr: '4px' }}
                  key={caller}
                  label={caller}
                  onDelete={() => onChipDelete('connectCallers', caller)}
                />
              ))}
            </Stack>

            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>How would you want us to explain your unavailability?</Typography>
            <TextField
              sx={{ mt: 1 }}
              value={formState.unavailabilityExplanations}
              onChange={(e) => onChange('unavailabilityExplanations')(e.target.value)}
              SelectProps={{
                sx: {
                  backgroundColor: '#E4ECFC',
                  borderColor: '#CBD8F1',
                },
                MenuProps: { sx: { width: 350 } },
              }}
              size="small"
              select
              fullWidth
            >
              {UNAVAILABILITY_OPTIONS.map(option => (
                <MenuItem
                  key={option}
                  value={option}
                >
                  <Typography>{option}</Typography>
                </MenuItem>
              ))}
            </TextField>

            <Collapse in={formState.unavailabilityExplanations === 'Other'}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                size="small"
                value={formState.unavailabilityExplanationsOther}
                onChange={(e) => onChange('unavailabilityExplanationsOther')(e.target.value)}
                InputProps={{
                  sx: { bgcolor: '#E4ECFC' },
                }}
                placeholder="Enter other explanation"
                FormHelperTextProps={{ sx: { textAlign: 'right' } }}
                helperText={formState.unavailabilityExplanationsOther.length + '/25'}
                inputProps={{ maxLength: 25 }}
              />
            </Collapse>

            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Additional Note</Typography>
            <TextField
              sx={{
                bgcolor: '#E4ECFC',
                borderRadius: '5px',
                mt: 1,
              }}
              InputProps={{ sx: { p: 1 } }}
              inputProps={{ style: { fontSize: '14px' }, maxLength: 250 }}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={formState.additionalNote}
              onChange={e => onChange('additionalNote')(e.target.value)}
            />
            <FormHelperText sx={{ textAlign: 'right', pr: '14px' }}>
              {formState.additionalNote + '/250'}
            </FormHelperText>
          </Box>
        </Collapse>

        <AnimatedButton
          onClick={handleSubmit}
          label={isSubmitting ? 'Saving...' : 'Save'}
          loading={isSubmitting}
          disabled={isSubmitting}
          sx={{
            mt: 4,
            width: 130,
            borderRadius: '15px',
          }}
        />
      </Box>
    </Dialog>
  );
};

export default ContactStatusDialog;
